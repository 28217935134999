@import "~bootstrap/scss/bootstrap";

body{
    color: rgb(72,70,91);
}

.dashboard {
    display: flex;
    justify-content: center;
}

.logo--md {
    height: 160px;
    margin: 30px 0;
}

.card-body--center {
    display: flex;
    justify-content: center;
}

#r-gen-badge-pill {
    padding: .4em .8em;
}

#r-gen-card {
    margin-bottom: 15px;
    -webkit-box-shadow:  0 7px 14px 0 rgba(60,66,87, 0.08), 0 3px 6px 0 rgba(0,0,0, 0.12);
    box-shadow:  0 7px 14px 0 rgba(60,66,87, 0.08), 0 3px 6px 0 rgba(0,0,0, 0.12);
    border: none;
}

#r-gen-card-dropdown-body {
    padding: 0 10px;
}

#r-gen-card-header {
    font-weight: 600;
    background-color: transparent;
    padding-left: 0;
    padding-right: 0;
    margin-left: 16px;
}

//Dashboard
#r-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    font-weight: 600;
}

#redist-container {
    padding-top: 25px;
    padding-left: 25px;
    padding-right: 25px;
}

//Report
#header1 {
    font-size: 24px;
    margin: 0;
}

#r-gen-filter-dropdown {
    max-height:183px;
    overflow: scroll;
}

.inlineToolTip > .tooltip-inner {
    max-width: 100%;
}

//Simple Report Entry

#report-pill {
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
    font-weight: 600;
    max-height: 30px;
    margin-right: 10px;
    font-size: 0.75rem;
}

#link-style {
    color: rgb(72,70,91); 
    width: 100%;
}   


//Address

//logotype
.logotype {
    width: 80px;
}

//GenReport

.map-canvas{
    width: 100%;
    height: 91vh;
    position: relative;
    flex: 3;
}

#designation-card {
    z-index: 1;
    position: absolute;
    top: 40px;
    right: 60px;
}

.designation--bullet-dis {
    color: rgb(162,165,185);
    text-decoration: line-through;
}
.designation--bold {
    font-weight: 600;
}

.address-input-field {
    color: rgb(116,120,141)
}

.map-output {
    display: flex;

}

.output {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    background: white;
}

//Incentive
#prog_alert {
    box-shadow: 0 7px 14px 0 rgba(60, 66, 87, 0.08), 0 3px 6px 0 rgba(0, 0, 0, 0.12);
}

#min-tab-height div {
    min-height: 30px;
    height: 30px;
}

#min-tab-height div button {
    min-height: 30px;
}

.program-headline {
    display: flex;
    margin-bottom: 30px;
}

.program-container {
    max-width: fit-content;
}

.program-container h3{
    margin: 0;
}

.app-process-info {
    max-width: 270px;
}

.program__classification {
    text-align: center;
    font-weight: 600;
    margin-bottom: 5px;
}

#report-pill-program {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    max-height: 30px;
    margin-right: 10px;
    font-size: 0.75rem;
    width: 70px;
}

//ProgramHeadline Pill
.prog-head-pill {
    margin-right: 10px;
}

// Program-entry
.program-entry--list-item {
    display: flex;
    flex:2;
    align-items: center;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 10px;
}

//Admin Program
#formCreateProgram textarea {
    height: 120px;
}

//Project Parameters
.modal-body .form-group {
    margin-bottom: 1rem;
}
.redistTip {
    border-bottom: 1px dashed rgba(0, 0, 0, 0.25);
    cursor: help;
}

#param-section {
    padding: 0 1.25rem;
}

// #param-section .btn-priamry {
//     color: #fff;
//     // background-color: inherit !important;
//     text-decoration: none;
//     // cursor: default !important;
//     // pointer-events: none !important;
// }

// #param-section .btn:hover {
//     // color: #5578eb;
//     // color: inherit;
//     // background-color: inherit !important;
//     text-decoration: none;
//     // cursor: default !important;
//     // pointer-events: none !important;
// }

//Estimator 

#estimatorInputs {
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }
    /* Firefox */
    input[type=number] {
    -moz-appearance: textfield;
    }
}

.estimateSectionContainer {
    display:flex;
    align-items: center;
    margin-top: 15px;
    max-width: 520px;
}

.estimateSectionTitle{
    flex:1;
    margin-bottom: 0;
}

.estimateSectionInput {
    flex:1;
}

//Modal custom Width
.custom-modal-width{
    width: 80vw !important;
    max-width: 80vw !important;
}