// Global config
@import "../../config";

// Layout config
@import "../../global/layout/config.scss";


.card-container {
    display: flex;
    flex: 1;
}

.card-static-map {
    display: flex;
    margin-right: 20px;
}

.card-static-status {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 2;
}

.card-static-info {
    display: flex;
    flex-direction: column;
    flex: 2;
    justify-content: space-between;
    color: rgb(116,120,141)
}

#card-text--right-align {
    display: flex;
    justify-content: flex-end;
}